
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import Button from 'ant-design-vue/lib/button'
import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
import router from './router.js'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import  VueVirtualScroller  from 'vue-virtual-scroller'
import promise from 'es6-promise'


      import 'xe-utils'
      import VXETable from 'vxe-table'
      import 'vxe-table/lib/style.css'

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.component(Button.name, Button)
Vue.use(VueVirtualScroller)
Vue.use(Antd);
promise.polyfill()
axios.defaults.baseURL = "";  

Vue.use(VXETable)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
