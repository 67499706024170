<template>
	<div>
	
	<div class="zl">
		<a-breadcrumb style="padding-top: 30px;background: #fff;padding-left: 8%;" >
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<span style="color: rgba(0, 0, 0, 0.45);">国家资历框架</span>
			</a-breadcrumb-item>
			
		</a-breadcrumb>
		<div class="zlbox">
			<p class="title">基于中国国家职业标准与新西兰国家资历框架下的职业证书等值性技术研究</p>
			<p class="content">2016年，普天合力作为核心研究团队参与了部级课题《中国新西兰职业证书等值性技术研究》的研究工作，在中新两国政府基于自贸协定框架下签署的职业资格证书互认议定书的基础上，以中国和新西兰职业资格证书职业标准（养老护理、园艺）为切入点，研究中国和新西兰职业资格证书标准体系、等级结构和划分依据等技术特点，对比整理分析中国和新西兰职业资格证书相关等值数据，运用实质相等理论等分析方法，重点解决职业活动等同、工作任务等价、职业技能相当和等级划分相似等关键技术问题，为互认工作提供了具有可操作性的技术方法和工具。</p>
		</div>
		<div class="zlbox">
			<p class="title">典型国家资历框架与职业资格证书制度相关性比较研究</p>
			<p class="content">2017年，普天合力研究团队与中国人事科学院合作开展国家社科基金教育学重点课题（AK160011）《国内外职业资格证书制度相比较研究——基于职业资格框架的视角》（GJ2017-01），以美国、英国、德国、韩国、日本、法国、加拿大、澳大利亚、新西兰、香港、新加坡、台湾、俄罗斯和印度等14个重点国家和地区为重点，开展了主要国家资历框架与职业资格证书体系比较分析与经验借鉴研究。</p>
		</div>
		<div class="zlbox">
			<p class="title">教育部学位中心国家资历框架研究</p>
			<p class="content">2021年，普天合力为教育部学位与研究生教育发展中心国家社科基金教育学重点课题《国家资历框架研究》（AKA160011），提供了大量英国、澳大利亚、南非和欧盟等代表性国家和地区的资历框架第一手资料，用于比较分析这些主要国家和地区资历框架建设的背景、发展历程、结构要素、支持条件和运行发展等具体内容。并基于对美国职业信息网络系统（O*NET）的长期深入研究，为国家职业资历框架数据平台设计与开发研究提供了设计思路。</p>
		</div>
		<div class="zlbox">
			<p class="title">探索开展技能人才评价成果在国家资历框架领域的应用试点研究与实践</p>
			<p class="content">2021年，普天合力作为核心研究团队参与了广州市职业能力建设指导中心的《探索开展技能人才评价成果在国家资历框架领域的应用试点研究与实践》课题研究工作，探索职业技能等级认定评价结果与专业技术人员职称、国际证书、行业企业证书贯通衔接、与广州市各技师学院过程评价结果对接、与企业高技能人才培养建设对接，探索不同层次和类型资历结构等联动体系，为广州市技能人才评价成果纳入国家资历框架范畴提供理论基础及技术路径，深入推进技能人才多维度评价体系的创新，畅通技能人才发展通道。</p>
		</div>
	</div>
	</div>
</template>

<script>

	export default{
		name: 'zl_Ind',
		
		data: function() {
			return {

			}
		}
	}
</script>

<style scoped>
	.zl .tit{
		background: #fff;
	}
	.zl .tit .titBox{
		width: 300px;
		border:3px dashed skyblue;
		border-radius: 10px;
		text-align: center;
		font-size: 30px;
		line-height: 100px;
		margin: 0 auto;
	}
	.zl .zlbox{
		padding: 4% 8%;
	}
	.zl .zlbox:nth-child(2n){
		background: #fff;
	}
	.zl .zlbox .title{
		font-size: 28px;
		color: #333333;
	}
	.zl .zlbox .content{
		font-size: 16px;
		color: #7B7D85;
		line-height: 30px;
		text-indent: 2em;
		text-align: justify;
	}
</style>