<template>
	<div id="app">
		<a-breadcrumb style="margin: 16px 0">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/FenleiIndex'}">职业分类大典</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/list1992'}">1992版工种目录</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item v-for="(item,index) in path_list" v-cloak :key="item.id">
				<router-link :to="{path:'/list1992',query:{category:'0'+(index+2),code:item.code}}">{{item.code}} {{item.name}}</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				{{detail.name}}
			</a-breadcrumb-item>
		</a-breadcrumb>
		<!--<a-spin :spinning="isLoading">-->
			<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
				<template v-if="category=='4'">
					<a-descriptions :title="detail.name" bordered :column="3">
						<a-descriptions-item label="名称">
							{{detail.name}}
						</a-descriptions-item>
						<a-descriptions-item label="代码">
							{{detail.code}}
						</a-descriptions-item>
						<a-descriptions-item label="数据类型">
							{{detail.type}}
						</a-descriptions-item>
					
						<!--<a-descriptions-item label="所属小类">
							<router-link :to="{path:'/list1992',query:{category:'04',code:path_list[2].code}}">{{path_list[2].code}} {{path_list[2].name}}</router-link>
						</a-descriptions-item>
						<a-descriptions-item label="定义" :span='2'>
							{{detail.definition}}
						</a-descriptions-item>-->
						<a-descriptions-item label="下列工种归入本职业" :span='3'>
							<span v-for="item in noc_list" :key="item.id">
							<router-link :to="{path:'/detail_1992',query:{category:'5',code:item.code}}" >{{item.code}} {{item.name}}</router-link><br/>
						</span>

						</a-descriptions-item>
					</a-descriptions>
				</template>
				<template v-if="category=='5'">
					<a-descriptions :title="detail.name" bordered :column="2">
						<a-descriptions-item label="代码">
							{{detail.code}}
						</a-descriptions-item>
						<a-descriptions-item label="名称">
							{{detail.name}}
						</a-descriptions-item>
						<a-descriptions-item label="数据类型">
							{{detail.type}}
						</a-descriptions-item>
						<a-descriptions-item label="所属职业">
							<router-link :to="{path:'/detail_1992',query:{category:'4',code:path_list[0].code}}">{{path_list[0].code}} {{path_list[0].name}}</router-link>
						</a-descriptions-item>
						<a-descriptions-item label="职业定义" :span='2'>
							{{detail.definition}}
						</a-descriptions-item>
						<a-descriptions-item label="适用范围" :span='2'>
							{{detail.range}}
						</a-descriptions-item>
						<a-descriptions-item label="相同职业下的其他工种" :span='2' style="vertical-align: top;">
							<span v-for="item in noc_list" :key="item.id">
							<router-link :to="{path:'/detail_1992',query:{category:'5',code:item.code}}" >{{item.code}} {{item.name}}</router-link><br/>
						</span>

						</a-descriptions-item>
					</a-descriptions>
				</template>
			</div>
		<!--</a-spin>-->

	</div>
</template>

<script>
	import Utils from '@/utils.js'
	export default {
		name: 'Detail1992',
		data: function() {
			return {
				cat_info: [],
				noc_info: [],
				result_arr: [],
				category: '',
				searchString: '',
				Height: 0,
				path_list: [],
				noc_list: [],
				detail: {
					
				},
				isLoading: false
			}
		},
		methods: {
			show: function(category, code) {
				
				var that = this;
				this.category = category
				switch(category) {
					case '4':
						{
							let code01 = code.slice(0, 2)

						
							for(let i = 0; i < that.noc_info.length; i++) {
								if(code01 == that.noc_info[i].code && that.noc_info[i].category == '04') {
									that.detail = that.noc_info[i];
								
									that.detail.type = '职业';
									
								}
								if(code01 == that.noc_info[i].code01 && that.noc_info[i].category == '05') {
									that.noc_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code
									})
								}
							}
							that.isLoading = false
							break;
						}
					case '5':
						{
							let code01 = code.slice(0, 2)
						

							
							for(let i = 0; i < that.noc_info.length; i++) {
								if(code01 == that.noc_info[i].code && that.noc_info[i].category == '04') {
									that.path_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code,
										type: '职业'
									})
								}
								if(code == that.noc_info[i].code && that.noc_info[i].category == '05') {
									that.detail = that.noc_info[i];
								
									that.detail.type = '工种';
									
								}
								if(code01 == that.noc_info[i].code01 && that.noc_info[i].category == '05') {
									
									that.noc_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code
									})
								}
							}console.log(that.path_list)
							for(let i = 0; i < that.noc_list.length; i++) {
								
								if(that.noc_list[i].code == that.detail.code) {
									that.noc_list.splice(i, 1)
								}
							}
							that.isLoading = false
							break;
						}
					default:
						that.isLoading = false
						break;
				}

			},
			search: function() {
				this.filteredArticles()
			},
			filteredArticles: function() {
				let search = this.searchString;
				this.searchString = ''
				this.$router.push({
					path: '/list1992',
					query: {
						q: search
					}
				});
			},
			getData() {
				var that = this;
				if(this.$route.query.category && this.$route.query.code) {
					let category = this.$route.query.category
					let code = this.$route.query.code
					
					if(category == '4' || category == '5') {
						this.$http.get('/json/noc_1992.txt').then(res => {
							that.noc_info = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							that.show(category, code)
						}).catch(error => console.log(error))
					} else {
						this.$router.push('/list1992');
					}
				} else {
					this.$router.push('/list1992');
				}
			}
		},
		beforeDestroy: function() {
			var that = this;
			that.searchString = ''
		},
		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			this.isLoading = true
			this.getData()
		},
		watch: {
			"$route": function() {
				this.isLoading = true
				this.cat_info = [],
					this.noc_info = [],
					this.result_arr = [],
					this.category = '',
					this.searchString = '',

					this.path_list = [],
					this.noc_list = [],
					this.detail = {

					}
				this.getData()
			}
		}
	}
</script>
<style>
body{
	background: #eee;
}
.ant-descriptions-item-label{
	vertical-align: top;
}
</style>