
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import FenleiList  from './components/fenlei_sys/2015/FenleiList.vue';
import Detail  from './components/fenlei_sys/2015/Detail.vue';
import Ind  from './components/Ind.vue';
import FenleiIndex  from './components/fenlei_sys/fenlei_Ind.vue';
import NewList from './components/fenlei_sys/new/FenleiList.vue';
import NewDetail  from './components/fenlei_sys/new/Detail.vue';
//import zj_Ind  from './components/zhuanjia/zj_Ind.vue';
import bz_Ind  from './components/biaozhun/bz_Ind.vue';
import List92 from './components/fenlei_sys/1992/FenleiList.vue';
import Detail1992  from './components/fenlei_sys/1992/Detail.vue';
import List99 from './components/fenlei_sys/1999/FenleiList.vue';
import Detail1999  from './components/fenlei_sys/1999/Detail.vue';
import BiaoZhun  from './components/biaozhun/2018/list.vue';
import ZhengCe  from './components/zhengce/zc_Ind.vue';
import BeforeBiaoZhun  from './components/biaozhun/before/list.vue';

import ZiLi  from './components/zili/zl_Ind.vue';
//定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {path:'/list2015',component:FenleiList},
    {path:"",component:Ind},
    {path:"/detail",component:Detail},
    {path:"/FenleiIndex",component:FenleiIndex},
    {path:"/new_list",component:NewList},
    {path:"/new_detail",component:NewDetail},
//  {path:"/zj",component:zj_Ind},
    {path:"/BiaoZhunIndex",component:bz_Ind},
    {path:"/list1992",component:List92},
    {path:"/detail_1992",component:Detail1992},
    {path:"/list1999",component:List99},
    {path:"/detail_1999",component:Detail1999},
    {path:"/bz",component:BiaoZhun},
    {path:"/zc",component:ZhengCe},
    {path:"/bz_before",component:BeforeBiaoZhun},
	{path:"/zlIndex",component:ZiLi},
]

//实例化VueRouter并将routes添加进去
const router=new VueRouter({
//ES6简写，等于routes：routes
//mode: 'history',
    routes
});

//抛出这个这个实例对象方便外部读取以及访问
export default router