<template>
	<div>
		<a-breadcrumb style="margin: 16px 0">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/BiaoZhunIndex'}">职业技能标准</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				2018年以前颁布的职业标准
			</a-breadcrumb-item>
		</a-breadcrumb>
		<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
			<div id='search-box' class="ant-col-6" style="margin-bottom: 0;">
				<a-input-search placeholder="请输入职业编码或标准名称" enter-button @search="onSearch" />
			</div>
			<br/><br/>

			<vxe-table border show-overflow highlight-hover-row class="mytable-scrollbar" :max-height="table_height" :scroll-y="{gt: 100}" :data='result_arr' ref="xTable1">
				<vxe-column type="seq" width='5%' title="序号"></vxe-column>
				<vxe-column field="code" width='10%' title="职业编码"></vxe-column>
				<vxe-column field="name" width='25%' title="标准名称"></vxe-column>
				<vxe-column field="org" width='22%' title="发文机构"></vxe-column>
				<vxe-column field="num" width='15%' title="发文号"></vxe-column>
				<vxe-column field="date" width='8%' title="颁布时间"></vxe-column>
				<vxe-column field="2015_occupatipn" title="对应2015版大典职业（工种）"></vxe-column>

			</vxe-table>
		</div>
		<!--<div v-if='result_arr.length>0'>-->
		<!--<RecycleScroller :item-size='30' class='scroller' :items='result_arr' >
			<a-table :columns="result_columns" :data-source="result_arr" :pagination="false" bordered rowKey='id' @change="handleTableChange()">

			</a-table>
		</RecycleScroller>-->
		<!--</div>-->

	</div>
</template>

<script>
	import { message } from 'ant-design-vue';
	import Utils from '@/utils.js'

	export default {
		name: 'BeforeBiaoZhun',
		data: function() {
			return {
				org_info: {},
				result_arr: [],
				searchString: '',
				table_height: ''
			}
		},

		methods: {
			info() {
				message.info('暂未开通');
			},
			filteredArticles: function(value) {

				this.result_arr = this.org_info
				var search = value;
				if(!search) {
					return;
				}
				search = search.trim();
				
				this.$nextTick(()=>{
				
				this.result_arr = this.result_arr.filter(function(item) {
					if(item.code.indexOf(search.toUpperCase()) != -1 || item.name.indexOf(search.toUpperCase()) != -1) {
						return item;
					}
			
				})
					setTimeout(()=>{
				
						this.$refs.xTable1.scrollTo(0, 0)
					},100)
				})

			},

			onSearch: function(value) {

				this.filteredArticles(value)

			},

			getData: function() {

			}
		},
		created: function() {
			var that = this;
			this.table_height = window.innerHeight - 150 + 'px'
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");

			});

			this.$http.get('/json/biaozhun_before.txt').then(reponse => {
				let res = JSON.parse(Utils.decrypt(reponse.data, 'taxcO9lLb8O4Jvmj'))
				that.org_info = res.biaozhun_list
				that.result_arr = res.biaozhun_list

			})

		}
	}
</script>
<style>
	#search-box {
		float: left;
		margin: 0px auto 16px;
		z-index: 100;
	}
	/*滚动条整体部分*/
	
	.mytable-scrollbar ::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	/*滚动条的轨道*/
	
	.mytable-scrollbar ::-webkit-scrollbar-track {
		background-color: #FFFFFF;
	}
	/*滚动条里面的小方块，能向上向下移动*/
	
	.mytable-scrollbar ::-webkit-scrollbar-thumb {
		background-color: #bfbfbf;
		border-radius: 5px;
		border: 1px solid #F1F1F1;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	}
	
	.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
		background-color: #A8A8A8;
	}
	
	.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
		background-color: #787878;
	}
	/*边角，即两个滚动条的交汇处*/
	
	.mytable-scrollbar ::-webkit-scrollbar-corner {
		background-color: #FFFFFF;
	}
</style>