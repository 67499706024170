<template>
	<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
		<div class="jineng jn" :style="jineng_height">
			<!--<h1 class="" style="text-align: center;">职业技能</h1>-->
			<a-row :gutter="16">
				<a-col :span="4">
					<a href="http://zszy.nvq.net.cn/htm/1/index.html" target="_blank">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/tu5.png"/>
						<p>专家视角</p>
						<!--<a href="http://zszy.nvq.net.cn/htm/1/index.html" target="_blank" class="link_btn">点击进入</a>-->
						<a href="http://zszy.nvq.net.cn/htm/1/index.html" target="_blank" class="link_btn">点击进入</a>
						
					</div>
					</a>
				</a-col>
				<a-col :span="4">
					<div class="box" :style="jnbox_height" @click="info">
						<img src="../../public/img/tu4.png"/>
						<p>职业技能研究</p>
						<div class="link_btn">点击进入</div>
						
					</div>

				</a-col>
				<a-col :span="4">
					<router-link :to="{path:'/FenleiIndex'}">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/shu1.png"/>
						<p>职业分类大典</p>
						<router-link :to="{path:'/FenleiIndex'}" class="link_btn">点击进入</router-link>
						
					</div>
					</router-link>
				</a-col>
				<a-col :span="4">
					<router-link :to="{path:'/BiaoZhunIndex'}">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/shu3.png"/>
						<p>职业技能标准</p>
						<router-link :to="{path:'/BiaoZhunIndex'}" class="link_btn">点击进入</router-link>
					</div>
					</router-link>
				</a-col>
				<a-col :span="4">
					<div class="box" :style="jnbox_height" @click="info">
						<img src="../../public/img/peixun.png"/>
						<p>职业技能培训</p>
						<div class="link_btn" >点击进入</div>
					</div>

				</a-col>
				<a-col :span="4">
					<a href="https://pjjg.nvq.net.cn" target="_blank">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/tu6.png"/>
						<p>职业技能评价</p>
						<a href="https://pjjg.nvq.net.cn" target="_blank" class="link_btn">点击进入</a>
					</div>
					</a>
					
				</a-col>
			</a-row>
			<a-row :gutter="16" style="margin-top:10px">
				<a-col :span="4">
					<router-link :to="{path:'/zlIndex'}">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/zlkj.png"/>
						<p>国家资历框架</p>
						<router-link :to="{path:'/zlIndex'}" class="link_btn">点击进入</router-link>
					</div>
					</router-link>
				</a-col>
				<a-col :span="4">
					<div class="box" :style="jnbox_height" @click="info">
						<img src="../../public/img/tu7.png"/>
						<p></p>
						<div class="link_btn" >点击进入</div>
					</div>

				</a-col>
				<a-col :span="4">
					<a href="https://book.nvq.net.cn" target="_blank">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/jiaocai.png"/>
						<p>职业技能教材</p>
						<a href="https://book.nvq.net.cn" target="_blank" class="link_btn">点击进入</a>
					</div>
					</a>
				</a-col>
				<a-col :span="4">
					<div class="box" :style="jnbox_height" @click="info">
						<img src="../../public/img/tiku.png"/>
						<p>职业技能题库</p>
						<div class="link_btn">点击进入</div>
					</div>

				</a-col>
				<a-col :span="4">
					<div class="box" :style="jnbox_height" @click="info">
						<img src="../../public/img/jingsai.png"/>
						<p>职业技能竞赛</p>
						<div class="link_btn">点击进入</div>
					</div>

				</a-col>
				<a-col :span="4">
					<a href="http://zszy.nvq.net.cn/htm/2/index.html" target="_blank">
					<div class="box" :style="jnbox_height">
						<img src="../../public/img/zcwj.png"/>
						<p>相关政策文件</p>
						<!--<a href="http://zszy.nvq.net.cn/htm/14887/index.html" target="_blank" class="link_btn">点击进入</a>-->
						<!--<router-link :to="{path:'/zc'}" class="link_btn">点击进入</router-link>-->
						<a href="http://zszy.nvq.net.cn/htm/2/index.html" target="_blank" class="link_btn">点击进入</a>
					</div>
					</a>
				</a-col>
				<!--<a-col :span="4">
					<div class="box">
						<img src="../../public/img/yanjiu.png"/>
						<p>职业技能研究</p>
						<div class="link_btn" @click="info">点击进入</div>
					</div>

				</a-col>-->
			</a-row>
		</div>
	
		<div class="item_list">
			
			<div class="item left_pic">
				<img src="../../public/img/jiaoyu.png" alt="" usemap="#Map"/>
				<map name="Map" id="Map">
							<area shape="rect" coords="80,120,40,25" href="http://nvqms.nvq.net.cn" target="_blank" title="司"/>
						</map>
				<div class="con">
					<p @click="info">现代职业教育</p>
					<div class="btn" @click="info">点击进入</div>
				</div>
				<div class="con">
					<p @click="info">技工院校教育</p>
					<div class="btn" @click="info">点击进入</div>
				</div>
				<div class="con">
					<p @click="info">国际证书</p>
					<div class="btn" @click="info">点击进入</div>
				</div>
			</div>
			<!--<div class="item right_pic">				
				<div class="con">
					<p>技工院校教育</p>
					<div class="btn" @click="info">点击进入</div>
				</div>
				<img src="../../public/img/jineng.png" alt="" style="height: 230px;border-radius: 10px;"/>
			</div>-->
		</div>
	</div>
</template>

<script>
	import { message } from 'ant-design-vue';
	export default {
		name: 'Ind',
		data: function() {
			return {
				jineng_height:{
					height:''
				},
				jnbox_height:{
					height:''
				}
			}
		},
		methods: {
			info(){
				message.info('暂未开通');
			}
		},
		beforeDestroy: function() {

		},
		
		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			let h=document.body.clientHeight
			this.jineng_height.height=0.66*h+'px'
			this.jnbox_height.height=0.66*h*0.47+'px'
		}
	}

</script>
<style>
	
	.jineng h1{
		font-weight: bold;
		font-size: 20px;
	}
	.jineng{
		margin-top: 30px;
	}
	.jineng .box {
		text-align: center;
		margin: 0 auto;
		height: 250px;
		border-radius: 5px;
		width: 90%;
		cursor: pointer;
	}
	.jn .box{
		height: 250px;
	}
	.jineng .box img{
		width: auto;
		height: 44%;
		
	}
	.jineng .box p{
		margin: 6% 0;
		font-size: 24px;
		color: rgba(0,0,0,0.65)
	}
	.jineng .box .link_btn {
		display: none;
		background: #0f63ba;
		color: #fff;
		width: 100px;
		margin: 0 auto;
		line-height: 30px;
		border-radius: 5px;
		cursor: pointer;
	}
	
	.jineng .box:hover {
		border: 1px solid #f5f5f5;
		border-top: 0;
		border-bottom: 5px solid #0f51b2;
		box-shadow: 0px 5px 0px 5px rgba(245, 245, 245, 0.35);
	}
	
	.jineng .box:hover p {
		font-weight: bold;
		color: #0f51b2;
	}
	.jineng .box:hover .link_btn{
		display: block;
	}
	.item_list{
		margin-top: 20px;
	}
	.item_list .item:nth-child(2n+1){
		background: #ebf7fe;
	}
	.item_list .item:nth-child(2n){
		background: #FFFFCC;
	}
	.item_list .item{
		height: 250px;
	}
	.item_list .item img{
		height: 98%;
    width: auto;
	}
	.item_list .item .con{
		display: inline-block;
		vertical-align: top;
		margin-top: 5%;
	}
	.item_list .item p{
		font-size: 28px;
		font-weight: bold;
		color: #0f51b2;
	}
	.item_list .item .btn{
		background: #0f63ba;
		color: #fff;
		width: 80px;
		margin: 0 auto;
		line-height: 30px;
		border-radius: 5px;
		cursor: pointer;
		text-align: center;
	}
	.item_list .left_pic img{
		margin-left: 5%;
	}
	.item_list .left_pic .con{
		margin-left: 5%;
	}
	.item_list .right_pic img{
		float: right;
		margin-right: 5%;
	}
	.item_list .right_pic .con{
		margin-left: 22%;
	}
	
</style>