<template>
	<div id="app">
		<a-breadcrumb style="margin: 16px 0">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/FenleiIndex'}">职业分类大典</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/new_list'}">2015版职业分类大典（动态更新版）</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item v-for="(item,index) in path_list" v-cloak :key="item.id">
				<router-link :to="{path:'/new_list',query:{category:'0'+(index+2),code:item.code}}">{{item.code}} {{item.name}}</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				{{detail.name}}
			</a-breadcrumb-item>
		</a-breadcrumb>
		<!--<a-spin :spinning="isLoading">-->
			<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
				<template v-if="category=='4'">
					<a-descriptions :title="detail.name" bordered :column="2">
						<a-descriptions-item label="名称">
							{{detail.name}}
						</a-descriptions-item>
						<a-descriptions-item label="代码">
							{{detail.code}}
						</a-descriptions-item>
						<a-descriptions-item label="数据类型">
							{{detail.type}}
						</a-descriptions-item>
						<a-descriptions-item label="所属小类">
							<router-link :to="{path:'/new_list',query:{category:'04',code:path_list[2].code}}">{{path_list[2].code}} {{path_list[2].name}}</router-link>
						</a-descriptions-item>
						<a-descriptions-item label="职业定义" :span='2'>
							{{detail.definition}}
						</a-descriptions-item>
						<a-descriptions-item label="下列工种归入本职业" :span='2'>
							<span v-for="item in noc_list" :key="item.id">
							<router-link :to="{path:'/new_detail',query:{category:'5',code:item.code}}" >{{item.name}}</router-link><br/>
						</span>

						</a-descriptions-item>
					</a-descriptions>
				</template>
				<template v-if="category=='5'">
					<a-descriptions :title="detail.name" bordered :column="2">
						<a-descriptions-item label="名称">
							{{detail.name}}
						</a-descriptions-item>
						<a-descriptions-item label="数据类型">
							{{detail.type}}
						</a-descriptions-item>
						<a-descriptions-item label="所属小类">
							<router-link :to="{path:'/new_list',query:{category:'04',code:path_list[2].code}}">{{path_list[2].code}} {{path_list[2].name}}</router-link>
						</a-descriptions-item>
						<a-descriptions-item label="所属职业">
							<router-link :to="{path:'/new_detail',query:{category:'4',code:path_list[3].code}}">{{path_list[3].code}} {{path_list[3].name}}</router-link>
						</a-descriptions-item>

						<a-descriptions-item label="相同职业下的其他工种" :span='2'>
							<span v-for="item in noc_list" :key="item.id">
							<router-link :to="{path:'/new_detail',query:{category:'5',code:item.code}}" >{{item.name}}</router-link><br/>
						</span>

						</a-descriptions-item>
					</a-descriptions>
				</template>
			</div>
		<!--</a-spin>-->

	</div>
</template>

<script>
	import Utils from '@/utils.js'
	export default {
		name: 'NewDetail',
		data: function() {
			return {
				cat_info: [],
				noc_info: [],
				result_arr: [],
				category: '',
				searchString: '',
				Height: 0,
				path_list: [],
				noc_list: [],
				detail: {
					name: '',
					code: '',
					type: '',
					definition: ''
				},
				isLoading: false
			}
		},
		methods: {
			show: function(category, code) {
				var that = this;
				this.category = category
				switch(category) {
					case '4':
						{
							let code01 = code.slice(0, 1)
							let code02 = code.slice(0, 4)
							let code03 = code.slice(0, 7)
							let code04 = code.slice(0, 10)
							for(let i = 0; i < that.cat_info.length; i++) {
								if(code01 == that.cat_info[i].code && that.cat_info[i].category == '01') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '大类'
									})
								}
								if(code02 == that.cat_info[i].code && that.cat_info[i].category == '02') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '中类'
									})
								}
								if(code03 == that.cat_info[i].code && that.cat_info[i].category == '03') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '小类'
									})
								}
							}
							for(let i = 0; i < that.noc_info.length; i++) {
								if(code04 == that.noc_info[i].code && that.noc_info[i].category == '04') {
									that.detail.name = that.noc_info[i].name;
									that.detail.code = that.noc_info[i].code;
									that.detail.type = '职业';
									that.detail.definition = that.noc_info[i].definition;
								}
								if(code04 == that.noc_info[i].code04 && that.noc_info[i].category == '05') {
									that.noc_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code
									})
								}
							}
							that.isLoading = false
							break;
						}
					case '5':
						{
							let code01 = code.slice(0, 1)
							let code02 = code.slice(0, 4)
							let code03 = code.slice(0, 7)
							let code04 = code.slice(0, 10)

							for(let i = 0; i < that.cat_info.length; i++) {
								if(code01 == that.cat_info[i].code && that.cat_info[i].category == '01') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '大类'
									})
								}
								if(code02 == that.cat_info[i].code && that.cat_info[i].category == '02') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '中类'
									})
								}
								if(code03 == that.cat_info[i].code && that.cat_info[i].category == '03') {
									that.path_list.push({
										name: that.cat_info[i].name,
										code: that.cat_info[i].code,
										type: '小类'
									})
								}
							}
							for(let i = 0; i < that.noc_info.length; i++) {
								if(code04 == that.noc_info[i].code && that.noc_info[i].category == '04') {
									that.path_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code,
										type: '职业'
									})
								}
								if(code == that.noc_info[i].code && that.noc_info[i].category == '05') {
									that.detail.name = that.noc_info[i].name;
									that.detail.code = that.noc_info[i].code;
									that.detail.type = '工种';
									that.detail.definition = that.noc_info[i].definition;
								}
								if(code04 == that.noc_info[i].code04 && that.noc_info[i].category == '05') {
									that.noc_list.push({
										name: that.noc_info[i].name,
										code: that.noc_info[i].code
									})
								}
							}
							for(let i = 0; i < that.noc_list.length; i++) {
								if(that.noc_list[i].code == that.detail.code) {
									that.noc_list.splice(i, 1)
								}
							}
							that.isLoading = false
							break;
						}
					default:
						that.isLoading = false
						break;
				}

			},
			search: function() {
				this.filteredArticles()
			},
			filteredArticles: function() {
				let search = this.searchString;
				this.searchString = ''
				this.$router.push({
					path: '/new_list',
					query: {
						q: search
					}
				});
			},
			getData() {
				var that = this;
				if(this.$route.query.category && this.$route.query.code) {
					let category = this.$route.query.category
					let code = this.$route.query.code
					if(category == '4' || category == '5') {
						this.$http.get('/json/new_cat.txt').then(res => {
							that.cat_info = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
						
						}).catch(error => console.log(error))
						this.$http.get('/json/new_noc.txt').then(res => {
							that.noc_info = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							that.show(category, code)
						}).catch(error => console.log(error))
					} else {
						this.$router.push('/new_list');
					}
				} else {
					this.$router.push('/new_list');
				}
			}
		},
		beforeDestroy: function() {
			var that = this;
			that.searchString = ''
		},
		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			this.isLoading = true
			this.getData()
		},
		watch: {
			"$route": function() {
				this.isLoading = true
				this.cat_info = [],
					this.noc_info = [],
					this.result_arr = [],
					this.category = '',
					this.searchString = '',

					this.path_list = [],
					this.noc_list = [],
					this.detail = {
						name: '',
						code: '',
						type: '',
						definition: ''
					}
				this.getData()
			}
		}
	}
</script>
<style>
body{
	background: #eee;
}
</style>