<template>
	<div>
		<a-breadcrumb style="margin: 16px 0" v-if="!('q' in this.$route.query)">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/FenleiIndex'}">职业分类大典</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/list2015'}">2015版职业分类大典（正式版）</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item v-for="(item,index) in path_list" v-cloak :key="item.id">
				<router-link :to="{path:'/list2015',query:{category:'0'+(index+2),code:item.code}}">{{item.code}} {{item.name}}</router-link>
			</a-breadcrumb-item>
		</a-breadcrumb>
		<a-breadcrumb style="margin: 16px 0" v-if="'q' in this.$route.query">
			当前位置：
			<a-breadcrumb-item>
				搜索结果
			</a-breadcrumb-item>
		</a-breadcrumb>
		<!--<a-spin :spinning="isLoading">-->
			<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">

				<div id='search-box' class="ant-col-6">
					<a-input-search placeholder="请输入名称或编码" enter-button @search="onSearch" />
				</div>
				<!--<p>{{isLoading}}</p>-->
				<a-table :columns="result_columns" :data-source="result_arr" :pagination="false" bordered rowKey='code' v-if="!('q' in this.$route.query)">
					<a slot="code" slot-scope="text,record" @click="next(record)" :class="record.num1>0||record.num2>0||record.category=='05'||record.num_xiaolei>0?'allowclick':'nonclick'">{{ text }}</a>
					<a slot="name" slot-scope="text,record" @click="next(record)" :class="record.num1>0||record.num2>0||record.category=='05'||record.num_xiaolei>0?'allowclick':'nonclick'">{{ text }}</a>
					<a slot="detail" slot-scope="text,record" @click="detail(record)">查看</a>
					<template slot="biaozhun" slot-scope="text,record">
						<a v-if="record.biaozhun" :href="record.biaozhun" target="_blank">查看</a>
					</template>
				</a-table>

				<a-table :columns="result_columns" :data-source="noc_search_result" :pagination="false" bordered rowKey="code" v-if=" 'q' in this.$route.query ">
					<template #title class="title">相关职业</template>
					<a slot="code " slot-scope="text,record " @click="next(record) " :class="record.num2>0?'allowclick':'nonclick'">{{ text }}</a>
					<a slot="name" slot-scope="text,record" @click="next(record)" :class="record.num2>0?'allowclick':'nonclick'">{{ text }}</a>
					<a slot="detail" slot-scope="text,record" @click="detail(record)">查看</a>
					<template slot="biaozhun" slot-scope="text,record">
						<a v-if="record.biaozhun" :href="record.biaozhun" target="_blank">查看</a>
					</template>
				</a-table>
				<a-divider />
				<a-table :columns="result_columns1" :data-source="craft_search_result" :pagination="false" bordered rowKey='code' v-if="'q' in this.$route.query">
					<template #title>相关工种</template>
					<a slot="name" slot-scope="text,record" @click="next(record)">{{ text }}</a>
					<a slot="detail" slot-scope="text,record" @click="detail(record)">查看</a>
					<template slot="biaozhun" slot-scope="text,record">
						<a v-if="record.biaozhun" :href="record.biaozhun" target="_blank">查看</a>
					</template>
				</a-table>
			</div>
		<!--</a-spin>-->
	</div>

</template>

<script>
	const columns = [{
			title: '编码',
			dataIndex: 'code',
			key: 'code',
			scopedSlots: {
				customRender: "code"
			},
		},
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
			scopedSlots: {
				customRender: "name"
			},
		},
		{
			title: '类型',
			dataIndex: 'type',
			customRender: (text, record) => {

				switch(record.category) {
					case '01':
						text = '大类'
						break;
					case '02':
						text = '中类'
						break;
					case '03':
						text = '小类'
						break;
					case '04':
						text = '职业'
						break;
					case '05':
						text = '工种'
						break;
					default:
						break;
				}
				return text

			},
		},

		{
			title: '职业数量',
			dataIndex: 'num1',
			key: 'num1',

		},
		{
			title: '工种数量',
			dataIndex: 'num2',
			key: 'num2',

		},
		{
			title: '查看详情',
			dataIndex: 'detail',
			scopedSlots: {
				customRender: "detail"
			},
		},
		{
			title: '职业标准',
			dataIndex: 'biaozhun',
			scopedSlots: {
				customRender: "biaozhun"
			},
		}
	];
	import Utils from '@/utils.js'
	export default {
		name: 'FenleiList',
		data: function() {

			return {

				columns,
				result_columns: columns,
				result_columns1: columns,
				search_info: [],
				result_arr: [],
				searchString: '',
				Height: 0,
				category: '',
				path_list: [],
				searching: 0,
				biaozhun_list: [],
				noc_search_result: [], //搜索结果职业list
				craft_search_result: [], //搜索结果工种list
				isLoading: false,
				loading_tag: 'loading'
			}
		},

		props: {
			msg: String
		},
		methods: {
			getdata() {
//				console.log('getdata')
				var that = this;
				that.searchString = ''
				this.result_arr = []
				if('q' in this.$route.query) { //包含搜索条件
					
					this.$http.get('/json/noc.txt').then(reponse => {
						that.search_info = JSON.parse(Utils.decrypt(reponse.data, 'taxcO9lLb8O4Jvmj'))
						that.searchString = this.$route.query.q
						that.filteredArticles()
					})

				} else if(this.$route.query.category && this.$route.query.code) { //具体职业工种
					that.category = this.$route.query.category
					that.getInfo(this.$route.query.category, this.$route.query.code)
				} else if(JSON.stringify(this.$route.query) == '{}') { //不包含参数默认起始数据
					this.$http.get('/json/cat.txt').then(res => {
						let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
						for(let i = 0; i < reponse.length; i++) {
							if(reponse[i].category == '01') {

								that.result_arr.push({
									name: reponse[i].name,
									code: reponse[i].code,
									category: reponse[i].category,
									num1: '',
									num2: ''
								});
							} else {
								break;
							}
						}

					}).catch(error => console.log(error))
					this.$http.get('/json/noc.txt').then(reponse => {
						let res = JSON.parse(Utils.decrypt(reponse.data, 'taxcO9lLb8O4Jvmj'))
						that.search_info = JSON.parse(Utils.decrypt(reponse.data, 'taxcO9lLb8O4Jvmj'))
						for(let i = 0; i < that.result_arr.length; i++) {
							let num1 = 0;
							let num2 = 0;
							for(let j = 0; j < res.length; j++) {
								if(res[j].category == '04' && res[j].code01 == that.result_arr[i].code) {
									num1++
								}
								if(res[j].category == '05' && res[j].code01 == that.result_arr[i].code) {
									num2++
								}
							}
							that.result_arr[i].num1 = num1
							that.result_arr[i].num2 = num2
						}
						that.loading_tag = 'loaded'
					}).catch(error => console.log(error))

				} else { //含有不规范参数
					this.$router.push('/list2015');
				}

			},
			getInfo(category, code) {
				var that = this
//console.log('getInfo')
				switch(category) {
					case '01':
						this.$router.push('/list2015');
						break;
					case '02':
					case '03':
						this.$http.get('/json/cat.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							if(category == '02') {
								for(let i = 0; i < reponse.length; i++) {
									if(reponse[i].category == category && reponse[i].code.slice(0, -3) == code) {
										that.result_arr.push({
											name: reponse[i].name,
											code: reponse[i].code,
											category: reponse[i].category,
											num1: '',
											num2: '',
											num_xiaolei: ''
										});
									}
									if(reponse[i].code == code && reponse[i].category == '01') {
										that.path_list.push({
											name: reponse[i].name,
											code: reponse[i].code,
											type: '大类'
										})
									}
								}
								for(let i = 0; i < that.result_arr.length; i++) {
									let num_xiaolei = 0
									for(let j = 0; j < reponse.length; j++) {
										if(reponse[j].category == '03' && reponse[j].code.slice(0, -3) == that.result_arr[i].code) {
											num_xiaolei++
										}
									}
									that.result_arr[i].num_xiaolei = num_xiaolei
								}

							}
							if(category == '03') {
								let code01 = code.slice(0, 1)
								for(let i = 0; i < reponse.length; i++) {
									if(reponse[i].category == category && reponse[i].code.slice(0, -3) == code) {
										that.result_arr.push({
											name: reponse[i].name,
											code: reponse[i].code,
											category: reponse[i].category,
											num1: '',
											num2: ''
										});
									}
									if(code01 == reponse[i].code && reponse[i].category == '01') {
										that.path_list.push({
											name: reponse[i].name,
											code: reponse[i].code,
											type: '大类'
										})
									}
									if(code == reponse[i].code && reponse[i].category == '02') {
										that.path_list.push({
											name: reponse[i].name,
											code: reponse[i].code,
											type: '大类'
										})
									}
								}
							}

						}).catch(error => console.log(error))
						this.$http.get('/json/noc.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							that.search_info = reponse
							let cd
							if(category == '02') {
								cd = 'code02'
							} else if(category == '03') {
								cd = 'code03'
							}
							for(let i = 0; i < that.result_arr.length; i++) {
								let num1 = 0;
								let num2 = 0;
								for(let j = 0; j < reponse.length; j++) {
									if(reponse[j].category == '04' && reponse[j][cd] == that.result_arr[i].code) {
										num1++
									}
									if(reponse[j].category == '05' && reponse[j][cd] == that.result_arr[i].code) {
										num2++
									}
								}
								that.result_arr[i].num1 = num1
								that.result_arr[i].num2 = num2
							}
							that.loading_tag = 'loaded'
						}).catch(error => console.log(error))

						break;
					case '04':

						this.$http.get('/json/cat.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							let code01 = code.slice(0, 1)
							let code02 = code.slice(0, 4)
							for(let i = 0; i < reponse.length; i++) {
								if(code01 == reponse[i].code && reponse[i].category == '01') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
								if(code02 == reponse[i].code && reponse[i].category == '02') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
								if(code == reponse[i].code && reponse[i].category == '03') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
							}

						}).catch(error => console.log(error))
						this.$http.get('/json/noc.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							that.search_info = reponse
							for(let i = 0; i < reponse.length; i++) {
								if(reponse[i].category == category && reponse[i].code.slice(0, -3) == code) {
									that.result_arr.push({
										name: reponse[i].name,
										code: reponse[i].code,
										category: reponse[i].category,
										num2: '',
										biaozhun: ''
									});
								}
							}
							for(let i = 0; i < that.result_arr.length; i++) {
								let num2 = 0;
								for(let j = 0; j < reponse.length; j++) {
									if(reponse[j].category == '05' && reponse[j].code04 == that.result_arr[i].code) {
										num2++
									}
								}
								that.result_arr[i].num2 = num2
								for(let k = 0; k < that.biaozhun_list.length; k++) {
									if(that.biaozhun_list[k].code == that.result_arr[i].code) {
										that.result_arr[i].biaozhun = that.biaozhun_list[k].web
										break
									}
								}

							}
							that.loading_tag = 'loaded'

						}).catch(error => console.log(error))

						break;
					case '05':
						this.$http.get('/json/cat.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							let code01 = code.slice(0, 1)
							let code02 = code.slice(0, 4)
							let code03 = code.slice(0, 7)
							for(let i = 0; i < reponse.length; i++) {
								if(code01 == reponse[i].code && reponse[i].category == '01') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
								if(code02 == reponse[i].code && reponse[i].category == '02') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
								if(code03 == reponse[i].code && reponse[i].category == '03') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
							}

						}).catch(error => console.log(error))
						this.$http.get('/json/noc.txt').then(res => {
							let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
							that.search_info = reponse
							for(let i = 0; i < reponse.length; i++) {
								if(reponse[i].category == category && reponse[i].code.slice(0, -4) == code) {
									that.result_arr.push(reponse[i]);
								}
								if(code == reponse[i].code && reponse[i].category == '04') {
									that.path_list.push({
										name: reponse[i].name,
										code: reponse[i].code,
										type: '大类'
									})
								}
							}

							that.loading_tag = 'loaded'
						}).catch(error => console.log(error))

						break;
					default:
						this.$router.push('/list2015');
						break;
				}
			},
			next: function(item) {
//				console.log('next')
				this.searchString = ''
				this.loading_tag = 'loading'
				if(item.category != '05') {
					let categ = parseInt(item.category) + 1
					let categ1 = '0' + categ
					if(item.num1 > 0 || item.num2 > 0 || item.num_xiaolei > 0) {
						this.$router.push({
							path: '/list2015',
							query: {
								category: categ1,
								code: item.code
							}
						});

					}

				}
				if(item.category == '05') {
					this.$router.push({
						path: '/detail',
						query: {
							category: 5,
							code: item.code
						}
					});

				}

			},
			detail: function(item) {
//				console.log('detail')
				this.searchString = ''
				if(item.category == '04') {
					this.$router.push({
						path: '/detail',
						query: {
							category: 4,
							code: item.code
						}
					});
				}
				if(item.category == '05') {

					this.$router.push({
						path: '/detail',
						query: {
							category: 5,
							code: item.code
						}
					});
				}
			},
			search: function() {
//				console.log('search')
				let search = this.searchString;
				this.$router.push({
					path: '/list2015',
					query: {
						q: search
					}
				})
			},
			filteredArticles: function() {
//				console.log('filteredArticles')
				var that = this
				let search = this.searchString;

				if(!search) {
					this.$router.push('/list2015');
					this.searching = 0
					return
				}

				this.searching = 1

				this.result_arr = this.search_info
				search = search.trim();
				this.result_arr = this.result_arr.filter(function(item) {
					if(item.code.indexOf(search) != -1 || item.name.indexOf(search) != -1) {
						if(item.category == '04') {
							let num = 0;

							for(let i = 0; i < that.search_info.length; i++) {
								if(item.code == that.search_info[i].code04 && that.search_info[i].category == '05') {
									num++

								}
							}
							item.num2 = num

							for(let k = 0; k < that.biaozhun_list.length; k++) {
								if(that.biaozhun_list[k].code == item.code) {
									item.biaozhun = that.biaozhun_list[k].web
									break
								}
							}
							that.noc_search_result.push(item)
						} else if(item.category == '05') {
							that.craft_search_result.push(item)
						}

						return item;
					}
					that.loading_tag = 'loaded'
				})
				
					
			},
//			go: function(a, b) {
//				console.log(b)
//			},
			table_col() {
				var that = this
				if('q' in this.$route.query) {
					that.result_columns = that.columns.filter(item => item.dataIndex !== 'num1')
					that.result_columns1 = that.columns.filter(item => item.dataIndex !== 'code' && item.dataIndex !== 'num1' && item.dataIndex !== 'num2')
				} else {

					switch(that.category) {
						case '04':
							that.result_columns = that.columns.filter(item => item.dataIndex !== 'num1')
							break;
						case '05':
							that.result_columns = that.columns.filter(item => item.dataIndex !== 'code' && item.dataIndex !== 'num1' && item.dataIndex !== 'num2' && item.dataIndex !== 'biaozhun')
							break;
						default:
							that.result_columns = that.columns.filter(item => item.dataIndex !== 'detail' && item.dataIndex !== 'biaozhun')
							break;
					}
				}
			},
			onSearch(value) {
				this.$router.push({
					path: '/list2015',
					query: {
						q: value
					}
				})
			}
		},
		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			var that = this
			this.loading_tag = 'loading'
//console.log('created')
			this.$http.get('/json/biaozhun.txt').then(res => {
				let reponse = JSON.parse(Utils.decrypt(res.data, 'taxcO9lLb8O4Jvmj'))
				that.biaozhun_list = reponse.biaozhun_list
			}).catch(error => console.log(error))
			this.getdata()
			this.table_col()
		},
		beforeDestroy: function() {
			var that = this;
			that.searchString = ''
			
		},
		watch: {
			"$route": function() {
				this.loading_tag = 'loading'
				this.search_info = []
				this.result_arr = []
				this.searchString = ''
				this.path_list = []
				this.category = ''
				this.noc_search_result = [] //搜索结果职业list
				this.craft_search_result = [] //搜索结果工种list
				this.getdata()
				this.table_col()
			},
			loading_tag: function(newName) {
				let t;
				let that = this
//				console.log("watch"+newName)
				if(newName == 'loading') {
					this.t = setTimeout(function() {
						that.isLoading = true
					}, 1000)
//					console.log('watch_loading')
				} else if(newName == 'loaded') {
					clearTimeout(t);
					that.isLoading = false
//					console.log('33333'+that.isLoading)
				}

				
			}
		},
		mounted: function() {
			this.table_col()
//			console.log('mounted')
//			this.loading_tag = 'loading's
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
	#title {
		text-align: center;
	}
	
	.title {
		text-align: center;
	}
	
	.allowclick {
		cursor: pointer;
	}
	
	.nonclick {
		cursor: default;
		color: rgba(0, 0, 0, 0.65)
	}
	
	#search-box {
		float: left;
		margin: 0px auto 16px;
		z-index: 100;
	}
</style>