<template>
	<div id="app" oncontextmenu="return false" onselectstart="return false" ondragstart="return false" oncopy=document.selection.empty()>
		<a-layout id="components-layout-demo-top" class="layout">
			<a-layout-header class="ant-row header" :style="banner_height">
				<div class="top_nav">
					<div class="log" @click="info">登录</div>
				</div>
				<img src="../public/img/jineng.png" />
				<h1 :style="banner_title" class="ant-col-12 header-left">终身职业技能培训信息服务网</h1>

			</a-layout-header>

			<a-layout-content :style="$route.path=='/zlIndex'?cont_min_height2:cont_min_height1">
				<router-view></router-view>
				<!--<FenleiList msg="Welcome to Your Vue.js App" />-->
			</a-layout-content>
		
			
			<a-layout-footer style="text-align: center;background: #0f51b2;padding:15px 50px 0;">
				<div class="footer-wrap">

					<p style="color: #fff;">
						<a-space size="middle"><span>技术支持单位：<a href="http://www.pthl.net/" target="_blank">北京普天合力通讯技术服务有限公司</a></span>
							<span>电话支持：010-85282055</span>
							<span><a href="https://beian.miit.gov.cn" target="_blank" style="">京ICP备08103976号</a></span>
							<span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102005461" target="_blank" style="">
								<img src="http://www.pthl.net/siteApp/images/zh_CN/t_pthl21/beianpic.png"/>京公网安备 11010102005461号
							</a></span>
						</a-space>
					</p>
				</div>

			</a-layout-footer>
		</a-layout>

	</div>
</template>

<script>
	//	import FenleiList from './components/FenleiList.vue'
	import { message } from 'ant-design-vue';
	export default {
		name: 'App',
		components: {
			//			FenleiList
		},
		data: function() {
			return {
				banner_height: {
					height: ''
				},
				banner_title: {
					color: '#ffffff',
					lineHeight: ''
				},
				cont_min_height1:{
					padding:'0 50px',
					minHeight:''
				},
				cont_min_height2:{
					padding:'0',
					minHeight:''
				}
			}
		},
		methods: {
			info() {
				message.info('暂未开通');
			}
		},
		created: function() {

			let h = document.body.clientHeight
			this.banner_height.height = h * 0.27 + 'px'
			this.banner_title.lineHeight = h * 0.27 + 'px'
			this.cont_min_height1.minHeight=h * 0.73-52+'px'
			this.cont_min_height2.minHeight=h * 0.73-52+'px'
			var _hmt = _hmt || [];
			(function() {
				var hm = document.createElement("script");
				hm.src = "https://hm.baidu.com/hm.js?01c5a36bd98771a38caa7377491b04eb";
				var s = document.getElementsByTagName("script")[0];
				s.parentNode.insertBefore(hm, s);
			})();

		}

	}
</script>

<style>
	#app {
		min-width: 1220px;
	}
	
	.header {
		background: url(../public/img/bannerbg.jpg) no-repeat center top;
		background-size: 100% 100%;
	}
	
	.header .top_nav .log {
		float: right;
		width: 90px;
		height: 40px;
		border-radius: 20px;
		border: 1px solid #fff;
		text-align: center;
		line-height: 40px;
		color: #fff;
		margin-top: 10px;
		cursor: pointer;
	}
	
	.header h1 {
		font-size: 42px;
		font-weight: bold;
		margin-left: 90px;
	}
	
	.header img {
		float: left;
		height: 100%;
		display: inline-block;
	}
	
	#components-layout-demo-top .logo {
		width: 120px;
		height: 31px;
		background: rgba(255, 255, 255, 0.2);
		margin: 16px 24px 16px 0;
		float: left;
	}
	
	.footer-wrap a {
		color: #ffffff;
	}

	/*#app {min-width: 1220px;}
	.header{
		background: url(../public/img/bannerbg.jpg) no-repeat center;
		height: 250px;
	}
	.header .top_nav .log{
		float:right;
		width: 90px;
		height: 40px;
		border-radius: 20px;
		border: 1px solid #fff;
		text-align: center;
		line-height: 40px;
		color: #fff;
		margin-top: 10px;
		cursor: pointer;
	}
	.header h1{
		font-size: 42px;
		line-height: 240px;
		font-weight: bold;
		margin-left: 90px;
	}
	.header img{
		float: left;
		height: 250px;
		display: inline-block;
	}

	#components-layout-demo-top .logo {
		width: 120px;
		height: 31px;
		background: rgba(255, 255, 255, 0.2);
		margin: 16px 24px 16px 0;
		float: left;
	}
	
	.footer-wrap a {
		color: #ffffff;
	}*/
</style>
<style scoped>
	
	
</style>