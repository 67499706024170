<template>
	<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
		<a-breadcrumb style="margin: 16px 0">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/FenleiIndex'}">职业分类大典</router-link>
			</a-breadcrumb-item>
	
		</a-breadcrumb>
		<div class="jineng">
			<!--<h1 class="" style="text-align: center;">职业技能</h1>-->
			<a-row :gutter="16">
				<a-col :span="6">
					<router-link :to="{path:'/list1992'}">
					<div class="box">
						<img src="../../../public/img/shu1.png" style="width: 60%;height: auto;" />
						<p>1992版工种目录</p>
						<router-link :to="{path:'/list1992'}" class="link_btn">点击进入</router-link>
						
					</div>
					</router-link>
				</a-col>
				<a-col :span="6">
					
					<div class="box">
						<router-link :to="{path:'/list1999'}">
						<img src="../../../public/img/shu2.png" style="width: 60%;height: auto;" usemap="#Map"/>
						</router-link>
						<map name="Map" id="Map">
							<area shape="rect" coords="0,0,30,20" href="http://nvqms.nvq.net.cn/nvqdbApp/htm/fenlei/index.html" target="_blank" title="司"/>
						</map>
						<p>1999版职业分类大典</p>
					
						<router-link :to="{path:'/list1999'}" class="link_btn">点击进入</router-link>
					</div>
					
				</a-col>
				<a-col :span="6">
					<router-link :to="{path:'/list2015'}">
					<div class="box">
						<img src="../../../public/img/shu4.png" style="width: 60%;height: auto;" />
						<p>2015版职业分类大典（正式版）</p>
						<router-link :to="{path:'/list2015'}" class="link_btn">点击进入</router-link>
						
					</div>
					</router-link>
				</a-col>
				<a-col :span="6">
					<router-link :to="{path:'/new_list'}">
					<div class="box">
						<img src="../../../public/img/shu1.png" style="width: 60%;height: auto;" />
						<p>2015版职业分类大典（动态更新版）</p>
						<router-link :to="{path:'/new_list'}" class="link_btn">点击进入</router-link>
					</div>
					</router-link>
				</a-col>
				<a-col :span="6">
					<a href="http://zszy.nvq.net.cn/htm/14884/index.html" target="_blank">
					<div class="box">
						<img src="../../../public/img/shu1.png" style="width: 60%;height: auto;" />
						<p>大典修订信息</p>
						
						<a class="link_btn" href="http://zszy.nvq.net.cn/htm/14884/index.html" target="_blank">点击进入</a>
						
					</div>
					</a>
				</a-col>
				<a-col :span="6">
					<!--<a href="https://ddxd.nvq.net.cn/" target="_blank">-->
					<div class="box">
						<img src="../../../public/img/shu2.png" style="width: 60%;height: auto;" />
						<p>2021修典信息服务平台</p>
						<!--<a class="link_btn" href="https://ddxd.nvq.net.cn/" target="_blank">点击进入</a>-->
						<div class="link_btn" @click="info">点击进入</div>
					</div>
					<!--</a>-->
				</a-col>
			</a-row>
			
		</div>
		
	</div>
</template>

<script>
	import { message } from 'ant-design-vue';
	export default {
		name: 'fenlei_Ind',
		data: function() {
			return {

			}
		},
		methods: {
			info(){
				message.info('暂未开通');
			}
		},
		beforeDestroy: function() {

		},
		
		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			
		}
	}
</script>
<style>
	.jineng h1{
		font-weight: bold;
		font-size: 20px;
	}
	.jineng{
		margin-top: 30px;
	}
	.jineng .box {
		text-align: center;
		margin: 0 auto;
		height: 280px;
		border-radius: 5px;
		width: 90%;
	}
	.jineng .box p{
		font-size: 24px;
	}
	.jineng .box .link_btn {
		display: none;
		background: #0f63ba;
		color: #fff;
		width: 100px;
		margin: 0 auto;
		line-height: 30px;
		border-radius: 5px;
		cursor: pointer;
	}
	
	.jineng .box:hover {
		border: 1px solid #f5f5f5;
		border-top: 0;
		border-bottom: 5px solid #0f51b2;
		box-shadow: 0px 5px 0px 5px rgba(245, 245, 245, 0.35);
	}
	
	.jineng .box:hover p {
		font-weight: bold;
		color: #0f51b2;
	}
	.jineng .box:hover .link_btn{
		display: block;
	}
</style>